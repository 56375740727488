import React, { useState } from "react";
import { useRouter } from "next/router";
import { Controller, useForm } from "react-hook-form";
import dynamic from "next/dynamic";
import { useTranslation } from "next-i18next";
import Link from "next/link";
// CONTEXT
import { useAuth } from "components/auth/hooks/useAuth";
import { useModal } from "@/components/context/ModalContext";
// VAT CHECK
import { checkVAT, countries } from "jsvat-next";
// COMPONENTS
import ButtonWithSpinner from "@/components/ui/CustomButton/ButtonWithSpinner";
const CustomModal = dynamic(() => import("@/components/portals/CustomModal/CustomModal"), { ssr: false });
import LoginForm from "@/components/core/LoginForm/LoginFormComponent";
// ICONS
import { EyeOffIcon, EyeIcon, WhatsAppIcon } from "@/public/icons/icons";
// BOOTSTRAP
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
// STYLE
import s from "./SignupForm.module.scss";
import { fetcher } from "@/pages/api/util/fetcher";
import { authError } from "@/components/auth/error/auth";
import { sortedCountryNames } from "@/common/utils/sharedFunctions";
// Sentry
import * as Sentry from "@sentry/browser";
import ReactSelect from "@/components/elements/ReactSelect/ReactSelect";
interface SignUpData {
  userEmail: string;
  userPassword: string;
  Nazione: string;
  PartitaIVA: string;
  privacy: boolean;
}

export default function SignupForm(props) {
  const auth = useAuth();
  const { control, register, errors, handleSubmit, getValues } = useForm();
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [invitation, setInvitation] = useState(props?.invitationEmail);

  // Nation state used by Vat check
  const [nationAlpha2Code, setNationAlpha2Code] = useState("");

  // Custom modal context state
  const { showModal, hideModal } = useModal();

  // i18n
  const { t } = useTranslation("common");

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClick = () => setShowPassword(!showPassword);

  /**
   * Checks if partitaIVA is unique on DB
   * @param partitaIVA
   * @returns
   */
  const validationPiva = async (partitaIVA: string) => {
    // set default value as {}
    let validation = {};

    const filter = { partitaiva: partitaIVA.toUpperCase() };
    await fetcher("/api/user/piva" + "?" + new URLSearchParams(filter).toString()).then((isPivaPresent) => {
      // On invitation must skip unique check
      if (partitaIVA != "00000000000" && isPivaPresent && !invitation) {
        validation = authError(validation, t("auth/piva-already-present"));
      } else {
        // Only on new users this validation has to be performed
        // Cheack if nationIsoCode is supported
        if (
          partitaIVA &&
          countries
            .map((ct) => ct.codes)
            .flat()
            .includes(getValues("Nazione"))
        ) {
          // formal check on piva, getValues is used here because nationAlpha2Code isn't set yet.
          if (partitaIVA != "00000000000" && !checkVAT(getValues("Nazione") + partitaIVA, countries).isValid) {
            validation = authError(validation, t("auth/piva-invalid-checksum"));
          }
        }
      }
    });
    return validation["error"] ? validation["error"].code : true;
  };

  const showAlertModal = () =>
    showModal(CustomModal, {
      closeFunction: () => router.push("/"),
      message: t("modals.registration-accepted"),
      closeButton: true,
    });

  const showLoginModal = () =>
    showModal(CustomModal, {
      backdrop: "static",
      children: <LoginForm />,
      closeButton: true,
    });

  // Custom modal context state
  // const modalState = useModal();

  const onSubmit = (data: SignUpData) => {
    // Trim email to prevent "Invalid Email Errors" from Firebase. Email must not be send with whitespaces
    data.userEmail = data.userEmail.trim();
    setIsLoading(true);
    setError(null);

    //if invitation is performed must exec, otherwise check the userType (not Compulsory)
    if (invitation) {
      return auth.invitation_signUp({ ...data, PartitaIVA: data["PartitaIVA"]?.toUpperCase(), lang: router.locale }).then((user) => {
        setIsLoading(false);
        if (user.error) {
          if (user.error) {
            setError(user.error);
            Sentry.captureMessage("[invitation_signUp]" + (user?.error.code || user?.error) + " on " + data.userEmail);
          }
        } else {
          // shows the feedback to the user
          showAlertModal();
        }
      });
    } else {
      return auth.signUp({ ...data, PartitaIVA: data["PartitaIVA"]?.toUpperCase(), lang: router.locale }).then((user) => {
        setIsLoading(false);
        if (user.error) {
          if (user.error) {
            setError(user.error);
            Sentry.captureMessage("[signUp]" + (user?.error.code || user?.error) + " on " + data.userEmail);
          }
        } else {
          // shows the feedback to the user
          showAlertModal();
        }
      });
    }
  };

  return (
    <Container className="px-0">
      <div className="text-center">
        {/* Email is present only on invitation */}
        {invitation ? (
          <>
            <h3 className="mb-3">{t("signup-modal.invite")}</h3>
            <p className="mb-4 fs-7">{t("signup-modal.invite-text")}</p>
          </>
        ) : (
          <>
            <h3 className="mb-2">{t("signup-modal.inscription")}</h3>
            <p className="mb-4 fs-7">{t("signup-modal.inscription-text")}</p>
          </>
        )}
      </div>
      <Form className={`fs-7 ${s.form_wrapper}`} onSubmit={handleSubmit(onSubmit)}>
        <div className="pb-2">
          <Form.Group className="mb-3" controlId="Nazione">
            <Form.Label className="required-label">{t("signup-modal.nation")}</Form.Label>
            {/* <Form.Control
              as="select"
              name="Nazione"
              ref={register({ required: "country-required" })}
              isInvalid={errors?.Nazione}
              onChange={(e) => setNationAlpha2Code(e.target.value)}
            >
              <option value=""></option>
              {sortedCountryNames(router.locale).map((country, index) => (
                <option value={country.value} key={index}>
                  {country.label}
                </option>
              ))}
            </Form.Control> */}
            <Controller
              control={control}
              defaultValue={""}
              name="Nazione"
              rules={{ required: "country-required" }}
              render={({ onChange, ref }, { invalid }) => (
                <ReactSelect
                  id="nation-form"
                  instanceId="nation-form"
                  closeMenuOnSelect={true}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  onChange={(option) => {
                    onChange(option?.value);
                    setNationAlpha2Code(option?.value);
                  }}
                  refRegister={ref}
                  noOptionsMessage={t("common.no-result", { ns: "common" })}
                  options={sortedCountryNames(router.locale)}
                  placeholder={""}
                  invalid={invalid}
                />
              )}
            />
            {errors?.Nazione && <p className="fs-8 invalid mb-0">{t("form." + errors?.Nazione.message)}</p>}
          </Form.Group>
          <Form.Group className="mb-3" controlId="piva">
            <Form.Label className="required-label">{t("signup-modal.vat-number")}</Form.Label>
            <InputGroup>
              <InputGroup.Text
                style={{
                  backgroundColor: "LightGray",
                  minWidth: "2.8rem",
                  borderRadius: "0",
                  textTransform: "uppercase",
                  display: "flex",
                  justifyContent: "center",
                  padding: "0",
                }}
              >
                <span>{nationAlpha2Code}</span>
              </InputGroup.Text>

              <Form.Control
                className={`shadow-none ${s.pivaform}`}
                style={{ textTransform: "uppercase" }}
                type="text"
                ref={register({
                  required: "auth/piva-required",
                  validate: validationPiva,
                })}
                isInvalid={errors?.PartitaIVA}
                name="PartitaIVA"
              />
            </InputGroup>
            {errors?.PartitaIVA && <p className="fs-8 invalid">{t("auth-errors." + errors.PartitaIVA.message)}</p>}
          </Form.Group>
          <Form.Group className="mb-3" controlId="userEmail">
            <Form.Label className="required-label">{t("signup-modal.email")}</Form.Label>
            <Form.Control
              autoComplete="off"
              defaultValue={invitation}
              type="search"
              name="userEmail"
              style={{ borderColor: errors.userEmail && "red" }}
              className={`shadow-none ${s.emailform}`}
              isInvalid={errors?.userEmail}
              ref={register({
                required: "auth/email-required",
                pattern: {
                  //TODO: this regular expression must be changed with a simple one
                  value: /(?:[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
                  message: "auth/invalid-email",
                },
              })}
            />
            {errors?.userEmail && <p className="fs-8 invalid">{t("auth-errors." + errors.userEmail.message)}</p>}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="required-label">{t("signup-modal.password")}</Form.Label>
            <InputGroup>
              <Form.Control
                autoComplete="off"
                type={showPassword ? "search" : "password"}
                className="shadow-none"
                name="userPassword"
                isInvalid={errors?.userPassword}
                ref={register({
                  required: "auth/password-required",
                  minLength: {
                    value: 6,
                    message: "auth/password-length",
                  },
                })}
              />

              <InputGroup.Text className="p-0 logovariant rounded-0" style={{ borderColor: errors.userPassword && "red" }}>
                <Button variant="link" className="shadow-none" onClick={handleClick}>
                  {showPassword ? (
                    <EyeIcon width="20" height="20" color={errors?.userPassword ? "red" : "black"} strokeWidth="1.5" />
                  ) : (
                    <EyeOffIcon width="20" height="20" color={errors?.userPassword ? "red" : "black"} strokeWidth="1.5" />
                  )}
                </Button>
              </InputGroup.Text>
            </InputGroup>
            {errors.userPassword && <p className="fs-8 invalid">{t("auth-errors." + errors.userPassword.message)}</p>}
          </Form.Group>
          {error?.code && (
            <>
              <div className="mb-4 d-flex flex-column text-danger text-center border border-danger p-2 rounded">
                {/* FIXME: Some errors are already translated, t add "auth-errors." to the error message. */}
                <span className="fs-7 fw-bold" dangerouslySetInnerHTML={{ __html: t(`auth-errors.${error.code}`) }} ></span>
              </div>
              {(error?.code == "auth/missing-pair-email-vat" || error?.code == "auth/user-expired") && (
                <div className="mb-4 text-success text-center border border-light rounded">
                  <div>
                    <Button
                      variant="light"
                      className={`mb-2 d-flex rounded align-items-center justify-content-center w-100 fs-7 fw-bold ${s.text_decoration_none_hover}`}
                      onClick={() => hideModal()}
                      href={`https://wa.me/${process.env.shopInfo["Contatti"].WhatsApp}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <WhatsAppIcon width={24} height={24} color="green" strokeWidth={0.1} />
                      <span className={`ps-2  ${s.text_decoration_none_hover}`}>{t("signup-modal.whatsapp")}</span>
                    </Button>
                    <Button
                      variant="light"
                      className={`w-100 fs-7 fw-bold rounded ${s.text_decoration_none_hover}`}
                      onClick={() => hideModal()}
                      href="/contact"
                    >
                      <span className={`${s.text_decoration_none_hover}`}>{t("signup-modal.customer-service")}</span>
                    </Button>
                  </div>
                </div>
              )}
            </>
          )}
          <div>
            {/* TODO: Add here AllowAdvertising */}
            <Form.Group controlId="newsletter">
              <Form.Check
                className="fs-8 "
                style={{ alignItems: "start" }}
                type="checkbox"
                label={<p className="mb-0">{t("signup-modal.accept-marketing")}</p>}
              />
            </Form.Group>
            <Form.Group controlId="privacy">
              <Form.Check
                className="fs-8"
                style={{ alignItems: "flex-end" }}
                type="checkbox"
                name="privacy"
                ref={register({ required: true })}
                label={
                  <p className="mb-0" style={{ color: errors.privacy && "red" }}>
                    {t("signup-modal.accept-policy")}
                  </p>
                }
              />
            </Form.Group>
          </div>
          <div className="my-2">
            <Form.Text>{t("signup-modal.accept-terms")}</Form.Text>
            <Form.Text> {t("signup-modal.explain-policy")}</Form.Text>
          </div>
        </div>
        <div className="py-2 justify-content-center">
          {errors.privacy && <Form.Label className="fs-8 invalid">{t("signup-modal.error-privacy")}</Form.Label>}
          <ButtonWithSpinner
            disabled={false}
            variant={"dark"}
            type={"submit"}
            isLoading={isLoading}
            divClassName="w-100"
            className={`p-2 ${s.button_style}`}
            style={{ height: "2.8rem" }}
          >
            {t("signup-modal.signup").toUpperCase()}
          </ButtonWithSpinner>
        </div>
        <Row className="justify-content-center">
          <Button variant="link" className="fs-7" onClick={showLoginModal}>
            {t("signup-modal.back-home")}
          </Button>
        </Row>
      </Form>
    </Container>
  );
}
